<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
         <el-form-item label="等级">
          <el-select
            v-model="queryForm.grade"
            placeholder="请选择等级"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in gradeList"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="标题">
          <el-input
            placeholder="请输入标题"
            v-model="queryForm.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加语法</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="syntaxList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
       
        <el-table-column label="等级" prop="grade" align="center"></el-table-column>
        <el-table-column label="标题" prop="title" align="center"></el-table-column>
        <el-table-column label="行" prop="line" align="center"></el-table-column>
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
       
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeTask(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加语法 -->
      <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="等级" label-width="100px">
          <el-select
            v-model="addForm.grade"
            placeholder="请选择等级"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in gradeList"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="行" label-width="100px">
          <el-select
            v-model="addForm.line"
            placeholder="请选择行"
            clearable
          >
            <el-option
              :label="item"
              :value="item"
              v-for="item in syntaxType"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="标题" label-width="100px">
            <el-input
              type="text"
              v-model="addForm.title"
              placeholder="请输入标题"
            />
        </el-form-item>

        <el-form-item el-form-item label="介绍" label-width="100px">
          <quill-editor
              v-model="addForm.content"
              ref="myQuillEditor"
            >
          </quill-editor>
        </el-form-item>
       
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");
import {
  SyntaxList,
  SyntaxType,
  SyntaxDel,
  SyntaxAdd,
  SyntaxEdit,
  getSyntaxCosToken,
} from "@/api/Korean/grammar.js";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        grade: "",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      syntaxType: [],
      syntaxList: [],
      gradeList: [],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        grade: "",
        line: "",
        title: "",
        content: "",
      },
      addtitle: "",
      isAdd: "add",
      lineData: [],
      isCascader: true,
    };
  },

  created() {
    this.getSyntaxType();
    this.getSyntaxList();
  },
  methods: {
    // 日语APP语法分类
    getSyntaxType() {
      SyntaxType().then((res) => {
        this.gradeList = res.data.grade;
        this.syntaxType = res.data.type;
      });
    },
    //   日语APP语法列表
    getSyntaxList() {
      SyntaxList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.syntaxList = res.data.data;
      });
    },

    // 删除语法
    async removeTask(id) {
      const result = await this.$confirm("是否要删除该语法?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      SyntaxDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除语法成功");
        this.getSyntaxList();
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加语法";
      this.addDialog = true;
    },
    //   选择行段
    handleLineChange(value) {
      this.lineData = value;
      this.addForm.line = this.lineData[0];
      this.addForm.part = this.lineData[1];
    },
    // 添加语法
    addNow() {
      if (this.addForm.grade == "") {
        this.$message.error("请选择等级");
        return;
      } else if (this.addForm.line == "") {
        this.$message.error("请选择行");
        return;
      }else if (this.addForm.title == "") {
        this.$message.error("请填写标题");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请填写介绍");
        return;
      }
      SyntaxAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加语法成功");
        this.addDialog = false;
        this.getSyntaxList();
      });
    },

    // 点击编辑图标
    editGoods(item) {
      this.isAdd = "edit";
      this.addtitle = "编辑语法";
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(item));
    },

    // 点击立即修改
    editReadNow() {
      if (this.addForm.grade == "") {
        this.$message.error("请选择等级");
        return;
      } else if (this.addForm.line == "") {
        this.$message.error("请选择行");
        return;
      } else if (this.addForm.title == "") {
        this.$message.error("请填写标题");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请填写介绍");
        return;
      }
      SyntaxEdit(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改语法成功");
        this.addDialog = false;
        this.getSyntaxList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        grade: "",
        line: "",
        title: "",
        content: "",
      };
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getSyntaxList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getSyntaxList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getSyntaxList();
    },
  },
};
</script>

<style scoped>
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
</style>
